.screen.internal.home {
    padding-left: unset;
    padding-right: unset;
}
.screen.home {
    display: flex;
    flex-direction: row;
}

.screen.home #left-panel {
    flex: 1;
    margin-right: 0px;
    padding: 0px;
    display: flex;
    width: 100%;
    max-width: 1714px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 50px;
}

.screen.home #right-panel {
    flex: 1;
    height: 80%;
}

.screen.home #right-panel .messages-panel {
    padding: 42px 35px 38px 37px;
    height: 100%;
    border-radius: 24px;
    background-color: #d3e9ff;
    background-color: #eeeeee;
    color: #cccccc;
    flex: 1;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    text-align: center;
    vertical-align: middle;
}

.screen.home .more-info-modal { 
    width: 80vw;
    max-width: 600px;
}

.screen.home .more-info-content { 
    font-family: 'SpaceGrotesk';
}

.screen.home .p-dialog .p-dialog-header .p-dialog-title {
    font-family: 'SpaceGrotesk';
    font-weight: bolder;
}