.screen.internal.sales-dashboard {
    display: flex;
    flex: 1;
    padding-bottom: 50px;
    padding-left: 5.3vw;
    padding-right: 5.3vw;
    padding-top: 100px;

}

.salesdashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1
}

.salesdashboard .chart-panel {
    padding: 37px 35px 43px 38px;
    border-radius: 24px;
    box-shadow: 0px 5px 23px 4.1px rgba(116, 150, 202, 0.58);
    background-color: #fff;
    flex: 2;
    display: flex;
    flex-direction: column;
}

.salesdashboard .chart-panel .chart-header {
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 26px;
}

.salesdashboard .chart-panel .chart-header .title {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.3;
    letter-spacing: -0.3px;
    text-align: left;
    color: #0052ca;
    height: 100%;
}

.salesdashboard .chart-panel .chart-header .filters {
    display: flex;
}

.salesdashboard .chart-panel .chart-header .filters.mobile {
    display: none;
}

.salesdashboard .chart-panel .chart-header .filters .filters-dropdown {
    margin-left: 16px;    
}

.salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown{
    border-radius: unset;
    border: none;
    border-bottom: 3px solid #80c0ff;
    height: 33px;
}

.salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown .p-dropdown-label,
.salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown .p-dropdown-item {
    color: #0052ca;
    font-family: Inter;
    font-size: 16.5px;
}

.salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown .p-dropdown-trigger {
    justify-content: flex-end;
    color: #80c0ff
}

.salesdashboard .chart-panel .chart-header .filters .filters-divider {
    height: 100%;
    width: 2px;
    margin-left: 16px;
    background-color: #e6e7e8;
}

.salesdashboard .chart-panel .metrics-and-chart {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.salesdashboard .chart-panel .metrics-and-chart .metrics {
    height: 186px;
    margin-bottom: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric {
    height: 100%;
    width: 16%;
    padding: 22px 17px 17px 17px;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric.disabled {
    background-color: #eeeeee !important;
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .content {
    flex: 1
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .value {
    font-family: SpaceGrotesk;
    font-size: 39.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;   
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .label {
    font-family: Inter;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.29px;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1400px){
    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .label {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.29px;
    }
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .label.disabled {
    color: #cccccc !important;   
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .value.disabled {
    color: #cccccc !important;   
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .check {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.salesdashboard .chart-panel .metrics-and-chart .metrics .metric .check.disabled {
    background-color: #eeeeee !important;
    cursor: not-allowed;
}

.salesdashboard .chart-panel .metrics-and-chart .chart {
    background-color: #fff;
    flex: 1;
    height:100%;
}

.salesdashboard .ranking-and-messages-panel {
    flex: 1;
    margin-left: 37px;
    display: flex;
    flex-direction: column;
}

.salesdashboard .ranking-panel {
    padding: 41px 37px 40px 35px;
    border-radius: 24px;
    background-color: #80c0ff;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.salesdashboard .ranking-panel .title {
    font-family: Inter;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.29px;
  text-align: left;
  color: #fefefe;
}

.salesdashboard .ranking-panel .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.salesdashboard .ranking-panel .filters .filters-dropdown {
    width: 49%;
}

.salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown{
    border-radius: unset;
    border: none;
    background-color: #80c0ff;
    border-bottom: 3px solid #0052ca;
    height: 33px;
    width: 100%;
}

.salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown .p-dropdown-label,
.salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown .p-dropdown-item {
    color: #06285e;
    font-family: Inter;
    font-size: 16.5px;
}

.salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown .p-dropdown-trigger {
    justify-content: flex-end;
    color: #0052ca
}

.salesdashboard .ranking-panel .ranking {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.salesdashboard .ranking-panel .ranking .ranking-item {
    display: flex;
    flex-direction: row;
    height: 54px;
    border-radius: 14px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    align-items: center;
    color: #0052ca;
    padding-left: 26px;
    padding-right: 26px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
}

.salesdashboard .ranking-panel .ranking .ranking-item.leader {
    background-color: #0052ca;
    color: #fff;
}

.salesdashboard .ranking-panel .ranking .ranking-item .position {
    color: inherit;
    margin-right: 26px;
    font-weight: 600;
}

.salesdashboard .ranking-panel .ranking .ranking-item .name {
    color: inherit;
    flex: 1;
}

.salesdashboard .ranking-panel .ranking .ranking-item .score {
    color: inherit;
    margin-left: 26px;
    font-weight: 600;
}

.salesdashboard .messages-panel {
    padding: 42px 35px 38px 37px;
    border-radius: 24px;
    background-color: #d3e9ff;
    background-color: #eeeeee;
    color: #cccccc;
    flex: 1;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    text-align: center;
    vertical-align: middle;
}