@media screen and (max-width: 1200px) {
    .screen.internal.home {
        padding-left: unset;
        padding-right: unset;
        /* flex-direction: column; */
        display: block;
    }

    .screen.home {
        display: flex;
        flex-direction: column;
    }
    
    .screen.home #left-panel {
        margin-right: 0px;
        padding: 0px;
        display: flex;
        max-width: 606px;
        padding-left: 26px;
        padding-right: 26px;
        width: 100%;
        padding-bottom: 120px;
        flex-direction: column;
        margin-top: 80px;
    }
    
    .screen.home #right-panel {
        flex: unset;
        padding: unset;
        width: 100%;        
        height: 220px;
    }
    
    .screen.home #right-panel .messages-panel {
        margin: 20px;
        padding: 42px 35px 38px 37px;
        border-radius: 24px;
        background-color: #d3e9ff;
        background-color: #eeeeee;
        color: #cccccc;
        height: unset;
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-content: center;
        text-align: center;
        vertical-align: middle;
    }
    
    .screen.home .more-info-modal { 
        width: 90vw;
        max-width: 600px;
    }
    
    .screen.home .more-info-content { 
        font-family: 'SpaceGrotesk';
    }
    
    .screen.home .p-dialog .p-dialog-header .p-dialog-title {
        font-family: 'SpaceGrotesk';
        font-weight: bolder;
    }
}