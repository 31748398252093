.screen.login {
    flex-direction: row;
}

.screen.login .logo {
    position: absolute;
    left: 5.35%;
    top: 6.38%;
}

.login-left-panel {
    width: 50%;
    height: 100%;
    padding-left: 8.6%;
    background-image: url('../images/login-background.png');
    background-size: cover;
    background-color: #06285e;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}



.login-left-panel .title {
    font-family: 'SpaceGrotesk';
    font-size: 65px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -1.3px;
    text-align: left;
    color: #fff;
    padding-bottom: 34px;
}

.login-left-panel .sub-title {
    font-family: 'Inter';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #80c0ff;
}

.login-right-panel {
    width: 50%;
    padding-left: 4.52%;
}

.login-right-panel.mobile {
    display: none;
}

.login-right-panel.desktop {
    display: flex;
}

.login-right-panel .fields {
    display: flex;
    flex-direction: row;
    margin-bottom: 28px;
}

.login-right-panel .fields .formfield{
    margin-right: 25px;
}

.login-right-panel .fields .formfield input {
    border: 0px;
    border-bottom: 2px solid #06285e;
    border-radius: 0;
    font-family: 'InterSemiBold';
    font-size: 24.5px;
    line-height: 32px;
    color: #06285e;
    outline: none !important;
}

.login-right-panel .fields .formfield input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #06285e;
    opacity: 1; /* Firefox */
  }

.login-right-panel .buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 28px;
}

.login-right-panel .button.login {
    width: 170px;
}

.login-right-panel .description {
    margin-bottom: 10px;
    font-family: 'Inter';
    color: #06285e;
    font-size: 24px;
}

.login-right-panel .existing-account-message {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Inter';
    color: #0052ca;
    font-size: 20px;
}

.login-right-panel .existing-account-message a, .login-right-panel .existing-account-message a:visited {
    color: #0052ca;
    font-weight: 600;
}

.login-right-panel .button.sendcode {
    width: 400px;
}

@media screen and (min-width: 1201px) and (max-width: 1450px) {
    .screen.login {
        flex-direction: row;
    }
    
    .screen.login .logo {
        position: absolute;
        left: 5.35%;
        top: 6.38%;
    }
    
    .login-left-panel {
        width: 50%;
        height: 100%;
        padding-left: 4.6%;
        background-image: url('../images/login-background.png');
        background-size: cover;
        background-color: #06285e;
        display: flex;
        flex-direction: column;
        justify-content: center;    
    }
    
    
    
    .login-left-panel .title {
        font-family: 'SpaceGrotesk';
        font-size: 55px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.95;
        letter-spacing: -1.3px;
        text-align: left;
        color: #fff;
        padding-bottom: 34px;
    }
    
    .login-left-panel .sub-title {
        font-family: 'Inter';
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.26px;
        text-align: left;
        color: #80c0ff;
    }
    
    .login-right-panel {
        width: 50%;
        padding-left: 2.52%;
    }
    
    .login-right-panel.mobile {
        display: none;
    }
    
    .login-right-panel.desktop {
        display: flex;
    }
    
    .login-right-panel .fields {
        display: flex;
        flex-direction: row;
        margin-bottom: 28px;
    }
    
    .login-right-panel .fields .formfield{
        margin-right: 25px;
    }
    
    .login-right-panel .fields .formfield input {
        border: 0px;
        border-bottom: 2px solid #06285e;
        border-radius: 0;
        font-family: 'InterSemiBold';
        font-size: 24.5px;
        line-height: 32px;
        color: #06285e;
        outline: none !important;
        width: 95%;
    }
    
    .login-right-panel .fields .formfield input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #06285e;
        opacity: 1; /* Firefox */
      }
    
    .login-right-panel .buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 28px;
    }
    
    .login-right-panel .button.login {
        width: 170px;
    }
    
    .login-right-panel .description {
        margin-bottom: 10px;
        font-family: 'Inter';
        color: #06285e;
        font-size: 24px;
    }
    
    .login-right-panel .existing-account-message {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: 'Inter';
        color: #0052ca;
        font-size: 20px;
    }
    
    .login-right-panel .existing-account-message a, .login-right-panel .existing-account-message a:visited {
        color: #0052ca;
        font-weight: 600;
    }
    
    .login-right-panel .button.sendcode {
        width: 400px;
    }
}

@media screen and (max-width: 1200px) {
    .screen.login .logo {
        position: absolute;
        left: 10.4%;
        top: 4%;
        width: 34.9%;
    }

    .login-left-panel {
        width: 100%;
        padding: 9.68%;
    }

    .login-left-panel .title {
        font-size: 30px;
        padding-top: 50px;
        margin-bottom: 00px;
    }

    .login-left-panel .sub-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .login-right-panel.mobile {
        display: flex;
        width: 100%;
        padding-left: 0;
    }
    
    .login-right-panel.desktop {
        display: none;
    }

    .login-right-panel .form-box {
        width: 100%;
    }

    .login-right-panel .fields {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    
    .login-right-panel .fields .formfield{
        margin: 0px;
        margin-bottom: 10px;
    }
    
    .login-right-panel .fields .formfield input {
        border: 0px;
        border-bottom: 2px solid #fff;
        border-radius: 0;
        font-family: 'InterSemiBold';
        font-size: 18px;
        line-height: 18px;
        color: #fff;
        outline: none !important;
        width: 100%;
        background-color: transparent;
    }
    
    .login-right-panel .fields .formfield input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1; /* Firefox */
      }
    
    .login-right-panel .buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 28px;
        padding: 0;
    }
    
    .login-right-panel .button.login {
        width: 100%;
        background-color: #81d553;
        margin: 0;
        font-weight: 600;
    }

    .login-right-panel .button div:nth-child(1){
        color:#06285e;
    }

    .login-right-panel .button div:nth-child(2){
        display: none;
    }
    
    .login-right-panel .description {
        margin-bottom: 10px;
        font-family: 'Inter';
        color: #fff;
        font-size: 16px;
    }
    
    .login-right-panel .existing-account-message {
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: 'Inter';
        color: #fff;
        font-size: 17px;
    }

    .login-right-panel .existing-account-message a, .login-right-panel .existing-account-message a:visited {
        color: #fff;
        font-weight: 600;
    }
    
    .login-right-panel .button {
        width: 100%;
        background-color: #81d553;
        margin: 0;
        color:#06285e;
        font-weight: 600;
    }

    .password-form-field {
        width: 100% !important;
    }
}