.sp_card-wrapper {
	height: 100%;
	min-width: 240px;
	margin-right: 5px;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;;
	overflow: scroll;
}

.sp_card-header {
	background: rgb(0, 0, 0);
	background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 81, 203, 1) 0%,
			rgba(113, 179, 248, 1) 100%
	);
	display: flex;
	flex-direction: column;
	color: #ffffff;
	height: 120px;
	overflow: hidden;
}

.sp_card-title {
	font-family: InterSemiBold;
	font-weight: normal;
	text-transform: uppercase;
	font-size: 14px;
}

.sp_card-header .sp_card-money-summary {
	font-weight: 600;
	width: 100%;
	display: grid;
	/*grid-template-columns: repeat(2, 1fr);*/
	justify-items: flex-start;
	font-size: 14px;
	font-family: InterSemiBold;
	font-weight: normal;
}

.sp_card-money-summary .sp_card-estimated {
	margin-top: 5px;
	font-size: 14px;
	font-family: InterSemiBold;
	font-weight: normal;
}

.sp_card-money-summary .sp_card-estimated span:last-of-type {
	color: #82d652;
}

.sp_progress_bar {
	width: 100%;
	display: flex;
	align-items: baseline;
}

.sp_progress_bar .sp_progress-wrapper {
	background-color: #deefff;
	width: 85%;
	padding: 3px;
	margin-top: 15px;
	max-width: 200px;
	border-radius: 10px;
	height: 10px;
	display: flex !important;
}

.sp_progress_bar .sp_progress-wrapper .sp_progress__bar {
	height: 100%;
	background-color: #82d652;
	border-radius: 10px;
	max-width: 100%;
}

.sp_progress_bar .sp_progress-percentage {
	margin-left: 0.5rem;
	font-size: 12px;
	font-weight: 700;
}

.sp_card-body--wrapper {
	background-color: #deefff;
	margin-top: -10px;
	border-radius: 10px 10px;
	padding: 0;
	overflow: hidden;
	flex: 1;
	display: flex;
	padding: 0;
}

.sp_card-body--items-wrapper {
	overflow-y: scroll;
	flex: 1;
	padding: 0px;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-bottom: 100px;
}


.sp_card-body--wrapper .sp_card-body--items-wrapper::-webkit-scrollbar-track {
	background: #c0dbf4;
	border-radius: 10px;
	padding: 10px 0;
	margin-top: 5px;
}


.sp_card-body--wrapper .sp_card-body--items-wrapper::-webkit-scrollbar-thumb {
	background: #8bbae6;
	border-radius: 10px;
}


.sp_card-body--wrapper
.sp_card-body--items-wrapper::-webkit-scrollbar-thumb:hover {
	background: #8bbae6;
}



@media screen and (min-width: 320px) {
	.sp_card-dots-wrapper {
		display: none;
	}
	
	.sp_card-header {
		justify-content: center;
		align-items: center;
	}
	
	.sp_card-title-wrapper {
		margin-bottom: 10px;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		display: flex;
	}
	
	.sp_card-title-wrapper i {
		font-size: 25px;
	}
	
	.sp_card-header {
		padding: 1.5rem 1.5rem 3rem 1.5rem;
		border-radius: 0;
	}
	
	.sp_card-header .sp_card-money-summary {
		grid-template-columns: repeat(2, 1fr);
		justify-items: flex-end;
	}
	
	.sp_card-money-summary .sp_card-estimated {
		margin-top: 0;
		justify-self: flex-start;
	}
	
	.sp_progress_bar {
		justify-content: center;
	}
	
	.sp_card-body--wrapper {
		border-radius: 0;
		padding: 0;
	}
	
	.sp_card-body--wrapper .sp_card-body--items-wrapper {
		overflow-y: auto;
		padding: 0;
	}
	
	.sp_card-body--wrapper .sp_card-body--items-wrapper::-webkit-scrollbar {
		width: 0px;
	}
}

@media screen and (max-width: 767px) {
	.sp_card-wrapper {
		width: 100%;
		height: 100%;
		margin-right: 0px;
	}
	
	.sp_card-total::after {
		content: "|";
		padding: 9px;
	}
}

@media screen and (min-width: 768px) {
	.sp_card-wrapper {
		flex: 1
	}
	.sp_card-dots-wrapper {
		display: block;
	}
	
	.pi-arrow-left,
	.pi-arrow-right {
		display: none;
	}
	
	.sp_card-title-wrapper {
		margin-top: 1rem;
	}
	
	.sp_card-header {
		align-items: flex-start;
		border-radius: 1.8rem 1.8rem 0 0;
	}
	
	.sp_card-header .sp_card-money-summary {
		grid-template-columns: 1fr;
		justify-items: flex-start;
	}
	
	.sp_card-header .sp_card-money-summary div {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
		justify-items: flex-start;
	}
	
	.sp_progress_bar {
		justify-content: flex-start;
	}
	
	.sp_card-body--wrapper {
		border-radius: 10px 10px;
		padding: 18px;
	}
	
	.sp_card-body--wrapper .sp_card-body--items-wrapper::-webkit-scrollbar {
		width: 9px;
	}
	
}
