.screen.internal.sales-pipeline {
    display: flex;
    flex: 1;
    padding-bottom: 50px;
    padding-left: 5.3vw;
    padding-right: 5.3vw;
    padding-top: 100px;

}

.salespipeline {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.salespipeline .filters {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.salespipeline .filters span {
    display: flex;
    padding: 5px;
}

.salespipeline .filters span.title {
    font-family: Inter;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.17px;
    text-align: left;
    color: #6d6e71;
    margin-right: 10px;
    align-items: center;
}

.salespipeline .columns{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow-y: scroll;
}


.salespipeline .filters.desktop {
    display: flex;
}

.salespipeline .filters.mobile {
    display: none;
}

.hidden-item-options{
    display:flex !important;
    align-items: baseline !important;
}

.float-right {
    float: right !important;
}

.salespipeline .filters-header .filters .filters-dropdown {
    margin-left: 16px;    
}

.salespipeline .filters-header .filters .filters-dropdown .p-dropdown{
    border-radius: unset;
    border: none;
    border-bottom: 3px solid #80c0ff;
    height: 33px;
}

.salespipeline .filters-header .filters .filters-dropdown .p-dropdown .p-dropdown-label,
.salespipeline .filters-header .filters .filters-dropdown .p-dropdown .p-dropdown-item {
    color: #0052ca;
    font-family: Inter;
    font-size: 16.5px;
}

.salespipeline .filters-header .filters .filters-dropdown .p-dropdown .p-dropdown-trigger {
    justify-content: flex-end;
    color: #80c0ff
}