@media screen and (max-width: 1200px) {
    .screen.internal.sales-pipeline {
        display: flex;
        flex: 1;
        padding-bottom: 0px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0px;
        overflow-y: hidden;
    }

    .salespipeline {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: hidden;
    }

    .salespipeline .columns{ 
        height: 100%;
    }

    .pi-arrow-left,
	.pi-arrow-right {
		cursor: pointer;
	}

    .pi-arrow-left.disable,
	.pi-arrow-right.disable {
		opacity: 0;
	}

    .sp_card-wrapper.disabled {
        /* display: none; */
    }

    /* .salespipeline .filters.desktop {
        display: none;
    } */

    .salespipeline .filters.mobile {
        display: flex;
        border-bottom: 2px solid #80c0ff;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .salespipeline .filters.mobile span {
        border: 2px solid #80c0ff;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-transform: uppercase;
        color: #80c0ff;
        cursor: pointer;
        padding: 10px 40px 10px 40px;
        margin: 0px;
    }

    .salespipeline .filters.mobile span.active {
        color: #0052ca;
        cursor: default;
    }

    .salespipeline .filters-header .filters .filters-dropdown {
        margin-left: 0px;    
        flex: 1
    }

    .salespipeline .filters-header .filters .filters-dropdown .p-dropdown{
        border-radius: unset;
        border: none;
        border-bottom: 3px solid #80c0ff;
        height: 33px;
        width: 100%;
    }

    .salespipeline .filters-header .filters .filters-dropdown .p-dropdown .p-dropdown-label,
    .salespipeline .filters-header .filters .filters-dropdown .p-dropdown .p-dropdown-item {
        color: #0052ca;
        font-family: Inter;
        font-size: 16.5px;
    }

    .salespipeline .filters-header .filters .filters-dropdown .p-dropdown .p-dropdown-trigger {
        justify-content: flex-end;
        color: #80c0ff
    }
}

@media screen and (max-width: 768px) {
    .salespipeline .filters.mobile {
        display: flex;
        border-bottom: 2px solid #80c0ff;
        margin-bottom: 0px;
        /* margin-top: 20px;  */
    }
}

@media screen and (max-width: 767px) {
    .salespipeline .filters.desktop {
        display: none;
    }
    .hidden-item-options span{
        display:flex !important;
        align-items: baseline !important;
        margin-right: 10px;
    }
}

@media screen and (min-width: 767px) {
    .salespipeline .filters.mobile {
        display: none;
    }
    .hiddenItems {
        display: none !important;
    }
}

@media screen and (max-width: 330px) {
    .salespipeline .filters.mobile {
        display: flex;
        border-bottom: 1px solid #80c0ff;
        margin-bottom: 0px;
        margin-left: 20px;
        margin-right: 20px;
    }
}