@media screen and (max-width: 1200px) {
    .screen.internal.sales-dashboard {
        display: flex;
        flex: 1;
        padding-bottom: 50px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0px;

    }

    .salesdashboard {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
    }

    .salesdashboard .chart-panel {
        padding: 10px 35px 10px 38px;
        border-radius: unset;
        box-shadow: none;
        background-color: #fff;
        flex: 2;
        display: flex;
        flex-direction: column;
        height: unset;
    }

    .salesdashboard .chart-panel .chart-header {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        justify-items: flex-start;
        align-content: flex-start;
        margin-bottom: 26px;
    }

    .salesdashboard .chart-panel .chart-header .title {
        display: flex;
        align-items: center;
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.3;
        letter-spacing: -0.3px;
        text-align: left;
        color: #0052ca;
        height: 36px;
    }

    .salesdashboard .chart-panel .chart-header .filters.desktop {
        display: none;
    }

    .salesdashboard .chart-panel .chart-header .filters.mobile {
        display: flex;
        width: 100%;
        margin-top: 20px;
    }

    .salesdashboard .chart-panel .chart-header .filters .filters-dropdown {
        margin-left: 0px;    
        flex: 1
    }

    .salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown{
        border-radius: unset;
        border: none;
        border-bottom: 3px solid #80c0ff;
        height: 33px;
        width: 100%;
    }

    .salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown .p-dropdown-label,
    .salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown .p-dropdown-item {
        color: #0052ca;
        font-family: Inter;
        font-size: 16.5px;
    }

    .salesdashboard .chart-panel .chart-header .filters .filters-dropdown .p-dropdown .p-dropdown-trigger {
        justify-content: flex-end;
        color: #80c0ff
    }

    .salesdashboard .chart-panel .chart-header .filters .filters-divider {
        height: 100%;
        width: 2px;
        margin-left: 16px;
        background-color: #fff;
    }

    .salesdashboard .chart-panel .metrics-and-chart {
        display: flex;
        flex-direction: column-reverse;
        flex: unset;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics {
        height: unset;
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        justify-content: unset;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric {
        height: unset;
        width: 100%;
        padding: 10px 0px 10px 0px;
        border-radius: unset;
        border-bottom: 2px solid #d1d3d4;
        display: flex;
        flex-direction: row;
        background-color: #fff !important;
        color: #06285e !important;
        align-items: center;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric.disabled {
        background-color: #fff !important;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .content {
        flex: 1;
        color: #06285e !important;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .value {
        font-family: SpaceGrotesk;
        font-size: 21px;
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;   
        color: #06285e !important;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .label {
        font-family: Inter;
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.29px;
        color: #06285e !important;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .label.disabled {
        color: #cccccc !important;   
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .icon {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 20px;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .icon.disabled {
        background-color: #eeeeee !important;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .check {
        width: 30px;
        height: 30px;
        background-color: #06285e !important;
        color: #fff !important;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
    }

    .salesdashboard .chart-panel .metrics-and-chart .chart {
        background-color: #fff;
        flex: unset;
        height: 70vw;
    }

    .salesdashboard .chart-panel .metrics-and-chart .metrics .metric .check.disabled {
        background-color: #eeeeee !important;
        cursor: not-allowed;
    }

    .salesdashboard .ranking-and-messages-panel {
        flex: unset;
        margin-left: 37px;
        margin-right: 37px;
        display: flex;
        flex-direction: column;
    }

    .salesdashboard .ranking-panel {
        padding: 30px 30px 30px 30px;
        border-radius: 24px;
        background-color: #80c0ff;
        margin-bottom: 35px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .salesdashboard .ranking-panel .title {
        font-family: Inter;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: -0.29px;
    text-align: left;
    color: #fefefe;
    }

    .salesdashboard .ranking-panel .filters {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        margin-bottom: 0px;
    }

    .salesdashboard .ranking-panel .filters .filters-dropdown {
        width: 100%;
        margin-bottom: 10px;
    }

    .salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown{
        border-radius: unset;
        border: none;
        background-color: #80c0ff;
        border-bottom: 2px solid #0052ca;
        height: 33px;
        width: 100%;
    }

    .salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown .p-dropdown-label,
    .salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown .p-dropdown-item {
        color: #06285e;
        font-family: Inter;
        font-size: 16.5px;
    }

    .salesdashboard .ranking-panel  .filters .filters-dropdown .p-dropdown .p-dropdown-trigger {
        justify-content: flex-end;
        color: #0052ca
    }

    .salesdashboard .ranking-panel .ranking {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .salesdashboard .ranking-panel .ranking .ranking-item {
        display: flex;
        flex-direction: row;
        height: 54px;
        border-radius: 14px;
        width: 100%;
        background-color: #fff;
        margin-bottom: 10px;
        align-items: center;
        color: #0052ca;
        padding-left: 20px;
        padding-right: 20px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
    }

    .salesdashboard .ranking-panel .ranking .ranking-item.leader {
        background-color: #0052ca;
        color: #fff;
    }

    .salesdashboard .ranking-panel .ranking .ranking-item .position {
        color: inherit;
        margin-right: 20px;
        font-weight: 600;
    }

    .salesdashboard .ranking-panel .ranking .ranking-item .name {
        color: inherit;
        flex: 1;
    }

    .salesdashboard .ranking-panel .ranking .ranking-item .score {
        color: inherit;
        margin-left: 20px;
        font-weight: 600;
    }

    .salesdashboard .messages-panel {
        display: none;
    }
}