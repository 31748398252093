.sp_card-item-wrapper {
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	display: block;
	flex-direction: row;
	flex-basis: content;
	justify-content: space-between;
	padding: 3px;
	margin-bottom: 3px;
	margin-right: 9px;
}

.sp_card-item-wrapper .sp_card-item-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 10px;
	font-family: Inter;
}

.sp_card-item-wrapper .sp_card-item-info h3,
p {
	color: #0051cb;
	font-weight: normal;
	font-size: 15px;
	font-family: InterSemiBold;
	margin-bottom: 0px;
	margin-top: 0px;
}

.sp_card-item-wrapper .sp_card-item-info h4 {
	font-size: 13px;
	font-family: Inter;
	margin-bottom: 10px;
	margin-top: 0px;
}

.sp_card-item-wrapper .sp_card-item-info p {
	font-size: 14px;
	font-family: Inter;
	margin-bottom: 0px;
}

.sp_card-item-wrapper .sp_card-item-info span:first-of-type {
	color: #82d652;
	font-weight: bold;
	font-size: 14px;
}

.sp_card-item-wrapper .sp_card-item-info span:last-of-type {
	font-style: italic;
	font-size: 14px;
	color: #6d6e71;
}

.sp_card-item-wrapper .card-item-actions {
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	flex-direction: column;
}

.sp_card-item-wrapper .card-item-actions i {
	font-size: 16px;
	color: #0051cb;
	cursor: pointer;
}

.sp_card-item-wrapper .card-item-actions i.bx-error {
	color: #f07272;
	cursor: inherit;
}

.sp_card-item-wrapper .card-item-actions i:not(:last-of-type) {
	padding-bottom: 10px;
}

@media screen and (min-width: 320px) {
	.sp_card-item-wrapper {
		box-shadow: none;
		margin-right: 0;
		padding: 10px;
	}
	
	.sp_card-item-wrapper {
		border-radius: 0;
	}
	
	.sp_card-item-wrapper .card-item-actions {
		justify-content: space-between;
	}
}

@media screen and (min-width: 768px) {
	.sp_card-item-wrapper {
		margin-right: 10px;
		box-shadow: 2px 1px 8px rgba(0, 82, 202, 0.2);
		padding: 8px;
		border-radius: 10px;
	}
	
	.sp_card-item-wrapper .card-item-actions {
		justify-content: flex-start;
	}
}
