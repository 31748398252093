.sp-card-item-detail-modal {
	width: 80vw;
	max-height: 90vh;
}

.sp-card-item-detail-escalate-modal {
	width: 70vw;
	max-height: 90vh;
}

.salespipeline .p-dialog-content {
	overflow-y: auto;
}

.salespipeline .p-dialog-mask.p-component-overlay {
	z-index: 10000 !important;
}

.salespipeline .p-button-icon-only {
	padding-left: 10px;
	padding-right: 10px;
}

.salespipeline .dialog-tab-menu {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	border-top: 1px solid #6d6e71;
	padding-top: 10px;
}

.salespipeline .dialog-tab-menu-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-weight: normal;
	font-size: 15px;
	font-family: InterSemiBold;
	text-transform: uppercase;
	color: #6d6e71;
	cursor: pointer;
}

.salespipeline .dialog-tab-menu-item.escalate {
	background-color: red;
	padding: 10px;
	border-radius: 10px;
	color: white;
}

.salespipeline .dialog-tab-menu-item.escalatebutton {
	background-color: red;
	padding: 10px;
	border-radius: 10px;
	color: white;
	width: 140px;
	display: flex;
	justify-content: space-around;
}

.salespipeline .dialog-tab-menu-item-icon {
	margin-right: 10px;
	margin-top: 2px;
}

.salespipeline .p-dialog-content h1 {
	font-size: 20px;
	font-family: InterSemiBold;
	text-transform: uppercase;
	color: #6d6e71;
	border-bottom: 1px solid #6d6e71;
	margin-top: 30px;
	padding-bottom: 10px;
}

.salespipeline .p-dialog-content h1:nth-of-type(1) {
	margin-top: 0px;
}

.salespipeline .p-dialog-content h1 span {
	margin-right: 10px;
}

.salespipeline .p-dialog-content .info-line {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;	
	margin-bottom: 10px;
}

.salespipeline .p-dialog-content .info-item {
	display: flex;
	flex-direction: column;	
	flex: 1;
}

.salespipeline .p-dialog-content .info-item-title {
	font-size: 18px;
	font-family: InterSemiBold;
	color: #6d6e71;
	margin-bottom: 5px;
}

.salespipeline .p-dialog-content .info-item-value {
	font-size: 18px;
	font-family: Inter;
	color: #6d6e71;
	margin-bottom: 10px;
}

.salespipeline .content-button {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-weight: normal;
	font-size: 15px;
	font-family: InterSemiBold;
	text-transform: uppercase;
	color: #0051cb;
	cursor: pointer;
	margin-top: 20px;
}

.salespipeline .content-button-icon {
	margin-right: 10px;
	margin-top: 2px;
}

.salespipeline .chat-item {
	display: flex;
	flex-direction: column;
}

.salespipeline .chat-item-header {
	display: flex;
	flex-direction: row;
	font-size: 16px;
	font-family: InterSemiBold;
	color: #6d6e71;
	margin-bottom: 5px;
}

.salespipeline .chat-item-content {
	font-size: 18px;
	font-family: Inter;
	color: #6d6e71;
	margin-bottom: 20px;
	text-align: justify;
}

.salespipeline .chat-item-header div {
	margin-right: 10px;
}

@media screen and (max-width: 768px) {

	.sp-card-item-detail-modal {
		width: 100vw;
		height: 100vh;
		max-height: unset;
	}

	.sp-card-item-detail-escalate-modal {
		width: 100vw;
		height: 100vh;
		max-height: unset;
	}

	.sp-card-item-detail-modal .p-dialog-content {
		height: 100%;
	}

	.salespipeline .p-dialog-header-icon {
		margin-left: 20px;
	}
	
	.salespipeline .p-dialog-header-icon span {
		font-size: 25px;
	
	}

	.salespipeline .p-dialog-content .info-line {
		flex-direction: column;
		margin-bottom: unset;
	}

}

/* .sp_card-item-wrapper {
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	display: block;
	flex-direction: row;
	flex-basis: content;
	justify-content: space-between;
	padding: 8px;
	margin-bottom: 7px;
	margin-right: 10px;
}

.sp_card-item-wrapper .sp_card-item-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 10px;
	font-family: Inter;
}

.sp_card-item-wrapper .sp_card-item-info h3,
p {
	color: #0051cb;
	font-weight: normal;
	font-size: 15px;
	font-family: InterSemiBold;
	margin-bottom: 5px;
}

.sp_card-item-wrapper .sp_card-item-info p {
	font-size: 14px;
	font-family: Inter;
}

.sp_card-item-wrapper .sp_card-item-info span:first-of-type {
	color: #82d652;
	font-weight: bold;
	font-size: 14px;
}

.sp_card-item-wrapper .sp_card-item-info span:last-of-type {
	font-style: italic;
	font-size: 14px;
	color: #6d6e71;
}

.sp_card-item-wrapper .card-item-actions {
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	flex-direction: column;
}

.sp_card-item-wrapper .card-item-actions i {
	font-size: 16px;
	color: #0051cb;
	cursor: pointer;
}

.sp_card-item-wrapper .card-item-actions i.bx-error {
	color: #f07272;
	cursor: inherit;
}

.sp_card-item-wrapper .card-item-actions i:not(:last-of-type) {
	padding-bottom: 10px;
}

@media screen and (min-width: 320px) {
	.sp_card-item-wrapper {
		box-shadow: none;
		margin-right: 0;
		padding: 10px;
	}
	
	.sp_card-item-wrapper {
		border-radius: 0;
	}
	
	.sp_card-item-wrapper .card-item-actions {
		justify-content: space-between;
	}
}

@media screen and (min-width: 768px) {
	.sp_card-item-wrapper {
		margin-right: 10px;
		box-shadow: 2px 1px 8px rgba(0, 82, 202, 0.2);
		padding: 8px;
		border-radius: 10px;
	}
	
	.sp_card-item-wrapper .card-item-actions {
		justify-content: flex-start;
	}
} */

.p-progress-spinner-submit {
    position: relative;
    margin: 0 auto;
    width: 25px;
    height: 25px;
}

.calender-icon {
	z-index: 10001 !important;
}