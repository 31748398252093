.homecard-container {
    width: 554px;
    max-width: 100%;
    min-height: 296px;
    margin-bottom: 32px;
    border-radius: 17px;
    background-color: #06285e;
}

.homecard-container.disabled {
    background-color: #dcddde;
}

.homecard-container .main {
    width: 100%;
    min-height: 236px;
    border-radius: 17px;
    background-color: #89c0f6;
    padding: 32px;
}

.homecard-container.disabled .main {
    background-color: unset;
    background: rgb(199,200,202);
    background: linear-gradient(315deg, rgba(199,200,202,1) 63%, rgba(137,192,246,1) 100%);
}

.homecard-container .main .title {
    width: 40%;
    font-family: Inter;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.48px;
    text-align: left;
    color: #06285e;
    margin-bottom: 38px;
}

.homecard-container.disabled .main .title {
    color: #fff;
}

.homecard-container .main .subtitle {
    display: flex;
    justify-content: space-between;
}

.homecard-container .main .subtitle .icon {
    height: 64px;
    width: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.homecard-container .main .subtitle .text {
    width: 50%;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.homecard-container.disabled .main .subtitle .text {
    color: #414042;
}

.homecard-container .footer {
    display: flex;
    height: 60px;
    flex-direction: row;
    align-items: center;
    padding-left: 32px;
}

.homecard-container .footer .link {
    font-family: Inter;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.84;
    letter-spacing: -0.29px;
    text-align: left;
    color: #fff;
    cursor: pointer;
}

.homecard-container.disabled .footer .link {
    cursor: default;
    color: #6d6e71;
}